<template>
  <div class="main">
    <div class="content" :style="{'width': style.width}">
      <div class="card" v-for="(item,i) in list" :key="i" @click="toDetail(item)">
        <div class="title">{{item.title}}</div>
        <div class="dir">{{item.dir}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      storeId: window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')).defaultStoreId : '',
      list: [],
      style: {
        width: '1250px'
      }
    }
  },
  computed: {},

  created () {},
  mounted () {
    this.checkLogin();
    this.getList();
    if(this.list.length > 4) {
      this.style.width = '1250px'
    }else {
      this.style.width = '850px'
    }
  },
  methods: {
    toDetail(row) {
      if(row.promId && this.storeId) {
        this.$router.push({
          path: '/nb/marketingTools/activity/detail',
          query: {
            id: row.promId,
            storeId: this.storeId,
          }
        })
      }else {
        this.$router.push('/nb/marketingTools/activity/list')
      }
    },
    checkLogin() {
      this.$axios({
        method: 'post',
        url: this.$api.navigation.list,
      }).then((row)=>{
        if(row.code === 0) {

        }
      })
    },
    getList() {
      this.$axios({
        method: 'get',
        url: this.$api.operation.promSignUp,
      }).then((row)=>{
        if(row.code === 0) {
          row.data.push({
            title: '更多活动',
          },)
          this.list = row.data;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  background: rgba(0,0,0, .5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  .content {
    width: 850px;
    height: 400px;
    // box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // background: #ccc;
    .card {
      width: 370px;
      height: 150px;
      margin: 0 20px;
      padding: 0 30px;
      border-radius: 12px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #656565;
      font-size: 20px;
      line-height: 22px;
      cursor: pointer;
      transition: transform .2s;
      > div {
        margin: 4px 0;
      }
      .title {
        font-weight: bold;
        text-align: center;
      }
    }
    .card:hover {
      transform: scale(1.05);
    }
  }
}
</style>