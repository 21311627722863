var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      { staticClass: "content", style: { width: _vm.style.width } },
      _vm._l(_vm.list, function (item, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "card",
            on: {
              click: function ($event) {
                return _vm.toDetail(item)
              },
            },
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.title))]),
            _c("div", { staticClass: "dir" }, [_vm._v(_vm._s(item.dir))]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }